import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CCard,
  LVenue,
  LBanquetIncs,
  LBanquetContact,
  ConceptMedia,
  LPickupList,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'PLAN',
            sub: 'プラン一覧',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/plan/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/plan/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '宴会・会議',
                path: '/banquet/',
              },
            ],
            current: {
              label: 'プラン一覧',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect">
        <LWrap>
          <LPickupList
            classification={['宴会・会議']}
            btnlabel="プラン詳細"
            contact="https://contact.royalparkhotels.co.jp/yrph/banquet?_ifbs-yrph_banquet_contact_form=s1_Step1"
            isword={true}
          />
        </LWrap>
      </div>
      <LVenue />
      <section className="u_mtMedium u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
